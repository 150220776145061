import React from "react";

const Badges = () => {
  return (
    <>
      <div className="container mx-4 max-w-[90%]">
        <div className="text-4xl font-bold ">
          <span className="text-[#1e6fe8]">Digital </span>Badges
        </div>

        <section class="text-gray-600 body-font">
          <div class="containerjustify-center px-5 py-24 mx-auto">
            <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
              <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full   flex-shrink-0">
                <img src="./images/badges/Python_101_Data_Science.png" alt="" />
              </div>
              <div class="flex-grow sm:text-left text-center  sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                  Python For Data Science
                </h2>
                <p class="leading-relaxed text-base">IBM</p>
                <button className=" shadow-2xl border-2 border-[#1e6fe8]  hover:bg-[#1e6fe8] hover:text-white rounded-lg px-4 py-2 mt-2 font-semibold">
                  <a href="https://www.credly.com/badges/9ce3e97e-5d38-4cee-b6a8-de3e1a15f58f">
                    Verify
                  </a>
                </button>
              </div>
            </div>
            <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
              <div class="flex-grow sm:text-right text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2 m">
                  Build Your Own Chatbot
                </h2>
                <p class="leading-relaxed text-base  items-center">IBM</p>
                <button className=" shadow-2xl border-2 border-[#1e6fe8]  hover:bg-[#1e6fe8] hover:text-white rounded-lg px-4 py-2 mt-2 font-semibold items-center">
                  <a href="https://www.credly.com/badges/ab8c9f07-4108-47e4-a237-3179bfa73597">
                    Verify
                  </a>
                </button>
              </div>
              <div class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full flex-shrink-0">
                <img
                  src="./images/badges/Build_your_own_chatbot_-_CC_v3.png"
                  alt=""
                />
              </div>
            </div>
            <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
              <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full  flex-shrink-0">
                <img src="./images/badges/image.png" alt="" />
              </div>
              <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                  Cybersecurity Fundamentals
                </h2>
                <p class="leading-relaxed text-base">IBM Skillsbuild</p>
                <button className=" shadow-2xl border-2 border-[#1e6fe8]  hover:bg-[#1e6fe8] hover:text-white rounded-lg px-4 py-2 mt-2 font-semibold">
                  <a href="https://www.credly.com/badges/c68f9604-03d9-4acf-9d87-aadc361ad83e">
                    Verify
                  </a>
                </button>
              </div>
            </div>
            <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
              <div class="flex-grow sm:text-right text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2 m">
                  Explore Emerging Tech
                </h2>
                <p class="leading-relaxed text-base">IBM Skillsbuild</p>
                <button className=" shadow-2xl border-2 border-[#1e6fe8]  hover:bg-[#1e6fe8] hover:text-white rounded-lg px-4 py-2 mt-2 font-semibold">
                  <a href="https://www.credly.com/badges/6c68aace-aeca-4887-a0af-1f4edf24c2c7">
                    Verify
                  </a>
                </button>
              </div>
              <div class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full flex-shrink-0">
                <img src="./images/badges/Explore_Emerging_Tech.png" alt="" />
              </div>
            </div>
            <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
              <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full  flex-shrink-0">
                <img
                  src="./images/badges/Working_in_a_Digital_World-_Professional_Skills.png"
                  alt=""
                />
              </div>
              <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                  Working in a Digital World Professional Skills
                </h2>
                <p class="leading-relaxed text-base">IBM Skillsbuild</p>
                <button className=" shadow-2xl border-2 border-[#1e6fe8]  hover:bg-[#1e6fe8] hover:text-white rounded-lg px-4 py-2 mt-2 font-semibold">
                  <a href="https://www.credly.com/badges/7a580209-d9f2-4baa-b8e7-d213a3228f30">
                    Verify
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Badges;
