import React from "react";

const Intro = () => {
  return (
    <>
      <div className="container max-w-[90%] my-28 flex-row flex-shrink sm:flex ">
        <div className="left text-center space-y-4 sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <div className="text-md text-[#1e6fe8] ">Hi There...</div>
          <div className="text-4xl font-bold text-[#1e6fe8]">VIKAS VATS</div>
          <div>I Am A Full-Stack Developer</div>
        </div>
        <div className="right sm:w-full md:w-1/2 md:h-48 lg:w-1/2 lg:h-64 xl:w-1/2 xl:h-72">
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_3rwasyjy.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </>
  );
};

export default Intro;
