import React from "react";

const Contact = () => {
  const cardArray = [
    {
      src: "https://assets10.lottiefiles.com/private_files/lf30_veaeyjlw.json",
      title: "My Email",
      value: "mrvikasvats1998@gmail.com",
    },
    {
      src: "https://assets5.lottiefiles.com/packages/lf20_uhiple7k.json",
      title: "My Number",
      value: "+49 1608379419",
    },
    {
      src: "https://assets10.lottiefiles.com/packages/lf20_lgpzpyna.json",
      title: "My Location",
      value: "Germany",
    },
  ];

  const contactCard = cardArray.map((item, pos) => {
    return (
      <div
        className="mail sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3   rounded-lg m-8 hover:shadow-2xl  hover:bg-[#1e6fe8] hover:text-white "
        key={pos}
      >
        <div className="content flex flex-col space-y-1 p-4">
          <div className="icon mx-auto h-24 w-24">
            <lottie-player
              src={item.src}
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="heading font-bold text-center">{item.title}</div>
          <div className="description text-center">{item.value}</div>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="container  max-w-[90%]">
        <div className="text-4xl font-bold ">
          <span className="text-[#1e6fe8] ml-4">Contact </span>Me
        </div>
        <div className="flex-row flex-shrink sm:flex">{contactCard}</div>
      </div>
    </>
  );
};

export default Contact;
