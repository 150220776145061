import React from "react";

const Skills = () => {
  const skillImgArray = [
    {
      src: "./images/skills/html-5.png",
      alt: "wait",
    },
    {
      src: "./images/skills/css-3.png",
      alt: "wait",
    },
    {
      src: "./images/skills/js.png",
      alt: "wait",
    },
    {
      src: "./images/skills/bootstrap.png",
      alt: "wait",
    },
    {
      src: "./images/skills/github.png",
      alt: "wait",
    },
    {
      src: "./images/skills/mysql.png",
      alt: "wait",
    },
    {
      src: "./images/skills/react.png",
      alt: "wait",
    },
    {
      src: "./images/skills/java.png",
      alt: "wait",
    },
    {
      src: "./images/skills/python.png",
      alt: "wait",
    },
    {
      src: "./images/skills/php.png",
      alt: "wait",
    },
    {
      src: "./images/skills/docker.png",
      alt: "wait",
    },
    {
      src: "./images/skills/c.png",
      alt: "wait",
    },
    {
      src: "./images/skills/motherboard.png",
      alt: "wait",
    },
    {
      src: "./images/skills/api.png",
      alt: "wait",
    },
  ];
  const skillCardArray = [
    {
      no: "2+",
      description: "Years",
      description2: "Experience",
    },
    {
      no: "3+",
      description: "Happy",
      description2: "Clients",
    },
    {
      no: "4+",
      description: "Projects",
      description2: "Completed",
    },
    {
      no: "2+",
      description: "Awards",
      description2: "Won",
    },
  ];

  const skillCardCard = skillCardArray.map((item) => {
    return (
      <div className="card flex flex-col font-bold shadow-2xl hover:bg-[#1e6fe8] hover:text-white rounded-lg p-11 m-2  h-25">
        <span className="text-black  text-4xl text-center">{item.no}</span>
        {item.description}
        <span className="">{item.description2}</span>
      </div>
    );
  });

  const skillImg = skillImgArray.map((img_item) => {
    return (
      <img className="w-28 h-28 p-4 " src={img_item.src} alt={img_item.alt} />
    );
  });

  return (
    <>
      <div className="container mx-4  mb-8 max-w-[90%]">
        <div className="text-4xl font-bold  mb-8">
          <span className="text-[#1e6fe8]">My </span>Skills
        </div>
      </div>
      <div className="container flex-row flex-wrap ">
        <div className="left justify-center flex flex-wrap lg:full md:w-full h-25 w-full">
          {skillImg}
        </div>

        <div className="right justify-center  flex flex-wrap lg:full md:w-full w-full ">
          {skillCardCard}
        </div>
      </div>
    </>
  );
};

export default Skills;
