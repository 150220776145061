import React from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

const Header = () => {
  return (
    <div className="text-gray-600 body-font">
      <div className="container-fluid  mr-0 flex flex-wrap p-5 flex-col md:flex-row items-center  bg-[#1e6fe8]">
        <span className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          🧑‍💻
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </span>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
          <span className="mr-5 text-white hover:text-gray-900 font-medium">
            <a href="https://github.com/VikasVats1998">
              <FaGithub />
            </a>
          </span>
          <span className="mr-5 text-white hover:text-gray-900 font-medium">
            <a href="https://www.linkedin.com/in/vikas-vats-a8040617b/">
              <FaLinkedinIn />
            </a>
          </span>
          <span className="mr-5 text-white hover:text-gray-900 font-medium">
            <a href="https://www.credly.com/users/vikas-vikas.14400038/badges">
              C
            </a>
          </span>
        </nav>
      </div>
    </div>
  );
};

export default Header;
