import React from "react";
import { FaShare } from "react-icons/fa";

const Project = () => {
  const cardProject = [
    {
      title: "TARAMAHAL",
      src: "./images/taramahal.jpg",
      link: "https://taramahal.mkdream.in/",
    },
    {
      title: "TXR2TEC",
      src: "./images/txr.jpg",
      link: "https://txr2tec.mkdream.in/",
    },
    {
      title: "PRSV",
      src: "./images/study-abroad.jpg",
      link: "https://prsvoverseas.com/",
    },
    {
      title: "DMS",
      src: "./images/img-2.jpg",
      link: "https://dairy.mkdream.in/login/",
    },
    {
      title: "Tour&Travell",
      src: "./images/tour.webp",
      link: "https://tour.mkdream.in/",
    },
  ];
  const prjectCard = cardProject.map((item) => {
    return (
      <div className="card relative  min-w-[40px] min-h-[40px] bg-white overflow-hidden rounded shadow-2xl m-8">
        <div className="background absolute bottom-4 left-2 z-0 font-semibold text-2xl">
          {item.title}{" "}
        </div>
        <span className="absolute top-4 right-2 z-0 bg-slate-200 rounded-lg p-3 ">
          <a href={item.link}>
            <FaShare />
          </a>
        </span>
        <img
          src={item.src}
          alt=""
          className=" cardimg relative z-10 h-[170px] w-[230px] p-1 rounded-md   "
        />
      </div>
    );
  });
  return (
    <>
      <div className="container mx-4 max-w-[90%]">
        <div className="text-4xl font-bold ">
          <span className="text-[#1e6fe8]">My </span>Projects
        </div>

        <div className="container flex flex-wrap justify-center">
          {prjectCard}
        </div>
      </div>
    </>
  );
};

export default Project;
