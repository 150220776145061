import React from "react";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Badges from "./components/badges/badges";
import Footer from "./components/footer/footer";
import Intro from "./components/intro/intro";
import Project from "./components/projects/project";
import Service from "./components/services/service";
import Skills from "./components/skills/skills";
import Header from "./components/header/header";

const App = () => {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Skills />
      <Service />
      <Badges />
      <Project />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
