import React from "react";

const Service = () => {
  const serviceArray = [
    {
      title: "Web Development",
      src: "https://assets6.lottiefiles.com/packages/lf20_a56yld9v.json",
    },
    {
      title: "Web Design",
      src: "https://assets10.lottiefiles.com/packages/lf20_ltt9yfth.json",
    },
    {
      title: "App Development",
      src: "https://assets6.lottiefiles.com/packages/lf20_a56yld9v.json",
    },
  ];

  const serviceCard = serviceArray.map((item) => {
    return (
      <div className="mail sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3  border-2 border-[#1e6fe8] rounded-lg m-8 hover:shadow-2xl    ">
        <div className="content flex flex-col space-y-1 p-4">
          <div className="icon mx-auto h-24 w-24">
            <lottie-player
              src={item.src}
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="heading font-bold text-center">{item.title}</div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="container mx-4 mt-8 max-w-[90%]">
        <div className="text-4xl font-bold ">
          <span className="text-[#1e6fe8]">My </span>Services
        </div>
        <div className="flex-row flex-shrink sm:flex ">{serviceCard}</div>
      </div>
    </>
  );
};

export default Service;
