import React from "react";

const About = () => {
  return (
    <>
      <div className="container mx-4 max-w-[90%]">
        <div className="text-4xl font-bold">
          About <span className="text-[#1e6fe8]">Me</span>
        </div>
        <div className="container my-8 flex-row flex-shrink sm:flex ">
          <div className="mleft sm:w-full   md:w-1/2 md:h-64 lg:w-1/2 lg:h-64 xl:w-1/2 xl:h-72">
            <lottie-player
              src="https://assets10.lottiefiles.com/private_files/lf30_WdTEui.json"
              background="transparent"
              speed="1"
              hover
              loop
              autoplay
            ></lottie-player>
          </div>
          <div className="mright sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
            <div className=" font-bold text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl">
              I Am A Developer
            </div>
            <div className="align-left">
              Looking Forward To An Opportunity For Working In A Dynamic,
              Challenging Environment, Where I Can Utilize My Skills For
              Developing My Career And For The Growth Of The Organization.
            </div>
            <div className="container flex">
              <div className="left mt-6">
                <ul className="space-y-2 ">
                  <li>
                    <span className="text-[#1e6fe8] text-xl font-bold ">
                      Email :
                    </span>{" "}
                    mrvikasvats1998@gmail.com
                  </li>
                  <li>
                    <span className="text-[#1e6fe8] text-xl font-bold">
                      Github :
                    </span>{" "}
                    <a href="https://github.com/VikasVats1998">
                      https://github.com/VikasVats1998
                    </a>
                  </li>
                  <li>
                    <span className="text-[#1e6fe8] text-xl font-bold">
                      Language :
                    </span>{" "}
                    Hindi, English, German A2
                  </li>

                  <li>
                    <span className="text-[#1e6fe8] text-xl font-bold">
                      Work :
                    </span>{" "}
                    Full-Stack Developer
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
