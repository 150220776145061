import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container bg-[#1e6fe8]   ">
        <div className="text text-center text-black text-[1.5rem] p-5 text-bold my-auto">
          Crafted with ❤️‍🔥 Love & 🕰️Time
        </div>
      </div>
    </>
  );
};

export default Footer;
